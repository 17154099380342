<template>
    <div id="footer">
        <div id="line" />
        <div id="footer-flex-wrapper">
            <div id="footer-content">
                <div class="d-flex justify-end">
                    <v-btn class="elevation-0 transparent rounded text-capitalize" @click="scrollOnTop">
                        <span class="button-text">
                            {{ $t('home.go_up') }}
                        </span>
                        <v-icon class="button-icon ml-3">mdi-arrow-up-bold-box-outline</v-icon>
                    </v-btn>
                </div>
                <div class="labels-wrapper">
                    <p class="my-2 mr-5"><a class="footer-link" target="_blank" :href="conditionsLink">{{ $t('home.conditions') }}</a></p>
                    <p class="my-2 mr-5"><a class="footer-link" target="_blank" :href="disclaimerLink">{{ $t('home.disclaimer') }}</a></p>
                    <p class="my-2 mr-5"><a class="footer-link" target="_blank" :href="privacyLink">{{ $t('home.privacy') }}</a></p>
                    <p class="my-2 mr-5"><a class="footer-link" target="_blank" :href="copyrightLink">{{ $t('home.copyright') }}</a></p>
                    <p class="my-2 mr-5"><a class="footer-link" target="_blank" :href="faqLink">{{ $t('home.faq') }}</a></p>
                </div>
                <v-divider class="footer-divider mt-2 mb-3" />
                <p class="copyright-text">
                    <strong>{{ $t('home.ansa_agency') }}</strong>
                    {{ $t('home.ansa_agency_description') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'footer-component',
    data() {
        return {
            conditionsLink: "https://www.ansa.it/sito/static/condizioni_generali_di_servizio.html",
            disclaimerLink: "https://www.ansa.it/sito/static/disclaimer.html",
            privacyLink: "https://www.ansa.it/sito/static/privacy.html",
            copyrightLink: "https://www.ansa.it/sito/static/copyright.html",
            faqLink: "https://www.ansa.it/sito/static/faq.html"
        };
    },
    methods: {
        scrollOnTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style lang="scss">
#footer  {
    width: 100%;
    height: auto;
    min-height: 178px;
    position: relative;
    bottom: 0;
    background-color: #2F2B3A;
    padding-bottom: 50px;

    #line {
        width: 100%;
        height: 8px;
        background-color: black;
    }

    #footer-flex-wrapper {
        display: flex;
        justify-content: center;

        #footer-content {
            width: 100%;
            max-width: 1185px;
            margin-top: 30px;
            margin-left: 7%;
            margin-right: 7%;

            .button-text {
                font-size: 16px !important;
                color: #96959C;
            }
        
            .button-icon {
                font-size: 22px !important;
                color: #E8EEEE;
            }
            
            .labels-wrapper {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                color: #E8EEEE;
                font-size: 14px;
            }
        
            .footer-divider {
                background-color: #53525E;
            }
        
            .copyright-text {
                font-size: 12px !important;
                color: #E8EEEE;
                font-size: 14px;
            }

            .footer-link {
                font-size: 13px;
                text-decoration: none !important;
                color: #E8EEEE !important;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    #footer {
        display: none;
    }
}
</style>