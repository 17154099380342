<template>
    <div v-if="!news">
        <div class="d-flex justify-center mt-16">
            <v-progress-circular indeterminate color="grey" />
        </div>
        <div class="text-center mt-4 grey--text">{{ $t('home.loading') }}</div>
    </div>
    <div v-else>
        <app-bar-component :recipient="news.recipient" />
        <main-component :news="news" />
        <footer-component />
    </div>
  </template>
  
  <script lang="js">
  import axios from 'axios';
  import dateformat from 'dateformat';
  import copy from 'copy-to-clipboard';
  import AppBar from './components/app-bar.vue';
  import Main from './components/main.vue';
  import Footer from './components/footer.vue';
  
  export default {
    name: 'home-view',
    components: {
        'app-bar-component': AppBar,
        'main-component': Main,
        'footer-component': Footer
    },
    data() {
      return {
        news: null,
      };
    },
    computed: {
      getNewsContextDate() {
        if (this.news?.newsContext?.date) {
          return dateformat(this.news.newsContext.date, 'dd/mm/yyyy HH:MM:ss');
        }
        return null;
      },
      showNewsContext() {
        return this.news?.newsContext?.title?.length && this.news?.newsContext?.date?.length;
      },
      newsCreatedAtComputedValue() {
        if (this.news && this.news.createdAt) {
          return dateformat(this.news.createdAt, 'dd/mm/yyyy, HH:MM:ss');
        }
        return null;
      },
      getRecipient: function () {
        if (this.news && this.news.recipient) {
          if (this.news.recipient.length > 48) {
            return this.news.recipient.substring(0, 48) + '...';
          }
          return this.news.recipient;
        }
        return "";
      },
    },
    methods: {
        async getNewsByHashOrCertificationHash() {
            const certificationHash = this.$route.params.certificationHash;
            const isExternalDns = window.location.host === process.env.VUE_APP_EXTERNAL_DNS;

            let url = `${process.env.VUE_APP_INTERNAL_DNS_VERIFICATION_SERVICE_URL}/verification/${certificationHash}/light`;

            if (isExternalDns) {
                url = `${process.env.VUE_APP_EXTERNAL_DNS_VERIFICATION_SERVICE_URL}/verification/${certificationHash}/light`;
            }

            axios
                .get(url)
                .then((result) => {
                    this.news = result.data;
                })
                .catch(() => {
                    this.$router.push({
                        name: 'not-found',
                    });
                });
        },
        goToTransactionInBlockchainExplorer() {
            window.open(process.env.VUE_APP_BLOCKCHAIN_TRANSACTION_URL.replace('TX_HASH', this.news.txHash));
        },
        copyToClipboard(fieldName) {
            copy(this.news[fieldName]);
        }
    },
    mounted() {
        this.getNewsByHashOrCertificationHash();
    }
};
</script>