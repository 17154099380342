import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './assets/styles/main.scss';
import i18n from './plugins/i18n';
import router from './router';
import 'animate.css';


new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
