<template>
    <div id="main">
        <div id="main-content">
            <h2 class="section-title">
                {{ news.message !== 'NEWS_NOT_NOTARIZED' ? $t('home.certified_news') : $t('home.certifying_news') }}
            </h2>
            <hr class="thick-divider mt-2 mb-4" />
            <p class="property-name-text mb-1"><strong>{{ $t('home.news_hash') }}</strong></p>
            <div class="d-flex justify-space-between align-center flex-wrap">
                <p class="property-name-value mt-0">{{ news.hash }}</p>
                <v-btn class="elevation-0 transparent rounded text-capitalize ma-0 pa-0" @click="copyToClipboard('hash')">
                    <p class="my-0 button-text">
                        {{ $t('home.copy') }}
                        <v-icon small class="copy-icon">mdi-content-copy</v-icon>
                    </p>
                </v-btn>
            </div>
            <hr class="thin-divider my-3" />
            <p class="property-name-text mb-1"><strong>{{ $t('home.certification_hash') }}</strong></p>
            <div class="d-flex justify-space-between align-center flex-wrap">
                <p class="property-name-value mt-0">{{ news.certificationHash }}</p>
                <v-btn class="elevation-0 transparent rounded text-capitalize ma-0 pa-0" @click="copyToClipboard('certificationHash')">
                    <p class="my-0 button-text">
                        {{ $t('home.copy') }}
                        <v-icon small class="copy-icon">mdi-content-copy</v-icon>
                    </p>
                </v-btn>
            </div>
            <template v-if="news.message !== 'NEWS_NOT_NOTARIZED'">
                <hr class="thin-divider my-3" />
                <p class="property-name-text mb-1"><strong>{{ $t('home.news_block_hash') }}</strong></p>
                <div class="d-flex justify-space-between align-center flex-wrap">
                    <p class="property-name-value mt-0">{{ news.merkleTreeRoot }}</p>
                    <v-btn class="elevation-0 transparent rounded text-capitalize ma-0 pa-0" @click="copyToClipboard('merkleTreeRoot')">
                        <p class="my-0 button-text">
                            {{ $t('home.copy') }}
                            <v-icon small class="copy-icon">mdi-content-copy</v-icon>
                        </p>
                    </v-btn>
                </div>
                <hr class="thin-divider my-3" />
                <div class="d-flex justify-start flex-wrap">
                    <p class="property-name-text mr-8 mb-1"><strong>{{ $t('home.certification_date') }}</strong></p>
                    <p class="property-name-value">{{ certificationDate }}</p>
                </div>
                <template v-if="showNewsContent">
                    <div class="mt-10">
                        <h2 class="section-title">{{ $t('home.news_content') }}</h2>
                    </div>
                    <hr class="thick-divider mt-2 mb-0" />
                    <div class="news-content-wrapper animate__animated animate__fadeIn">
                        <h2 class="news-context-title">{{ news.newsContext.title }}</h2>
                        <h4 v-if="news.newsContext.subtitle?.length" class="mt-3 news-context-subtitle">{{ news.newsContext.subtitle }}</h4>
                        <p class="mt-8 property-name-value news-context-date">
                            <v-icon color="black" size="18px">mdi-calendar-blank-outline</v-icon>
                            {{ $t('home.created_at') }} {{ newsDate }}
                        </p>
                    </div>
                </template>
                <div class="explorer-button-wrapper mt-12">
                    <v-btn class="elevation-0 rounded-0 text-capitalize px-4 py-5" outlined @click="goToBlockchainExplorer()">
                        <p class="my-0 button-text">
                            <strong>{{ $t('home.view_in_public_registry') }}</strong>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </p>
                    </v-btn>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="js">
import copy from 'copy-to-clipboard';
import dateformat from 'dateformat';

export default {
    name: 'main-component',
    props: {
        news: {
            type: Object,
            required: true
        }
    },
    computed: {
        certificationDate() {
            return dateformat(this.news.createdAt, 'dd/mm/yyyy, HH:MM:ss');
        },
        newsDate() {
            if (this.news?.newsContext?.date) {
                return dateformat(this.news.newsContext.date, 'dd/mm/yyyy HH:MM:ss');
            }
            return null;
        },
        showNewsContent() {
            return this.news?.newsContext?.title?.length && this.news?.newsContext?.date?.length;
        }
    },
    methods: {
        copyToClipboard(fieldName) {
            copy(this.news[fieldName]);
        },
        goToBlockchainExplorer() {
            window.open(process.env.VUE_APP_BLOCKCHAIN_TRANSACTION_URL.replace('TX_HASH', this.news.txHash));
        }
    }
};
</script>

<style lang="scss">
#main  {
    width: 100%;
    min-height: calc(100vh - 310px);
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;

    #main-content {
        width: 100%;
        max-width: 1185px;
        height: auto;
        margin-left: 7%;
        margin-right: 7%;

        .copy-icon {
            font-size: 23px !important;
        }

        .thick-divider {
            height: 4px;
            background-color: #E3E3E6;
            border: none;
        }

        .thin-divider {
            height: 1px;
            background-color: #E3E3E6;
            border: none;
        }

        .section-title {
            font-size: 24px;
            color: #33B233;
        }

        .property-name-text, .property-name-value {
            font-size: 16px;
            word-break: break-all !important;
        }

        button {
            .button-text {
                font-size: 14px;
            }
        }

        .news-content-wrapper {
            background-color: #F3F7F7;
            padding: 20px;

            .news-context-title {
                font-size: 30px;
            }

            .news-context-subtitle {
                font-size: 18px;
            }

            .news-context-date {
                font-size: 13px;
            }
        }

        .explorer-button-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: 500px) {
    #main #main-content .section-title {
        font-size: 20px;
    }
    #main #main-content .explorer-button-wrapper {
        justify-content: flex-start !important;
    }

    #main #main-content .news-content-wrapper .news-context-date {
        font-size: 14px !important;
    }
}
</style>