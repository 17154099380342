<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script lang="js">
export default {
  name: 'app-view',
};
</script>
