<template>
    <div id="app-bar">
        <div id="app-bar-content">
            <div class="mr-4" v-if="!isNewsForAnsa">
                <h2 class="title"><strong>{{ recipient }}</strong></h2>
            </div>
            <div v-else />
            <img class="ansa-logo my-4" src="../../../assets/images/ANSAcheck-logo.svg" />
        </div>
    </div>
</template>

<script lang="js">
const ANSA_RECIPIENT = 'ansa';

export default {
    name: 'app-bar-component',
    props: {
        recipient: {
            type: String,
            required: true
        }
    },
    computed: {
        isNewsForAnsa() {
            return this.recipient.toLowerCase() === ANSA_RECIPIENT;
        }
    }
};
</script>

<style lang="scss">
#app-bar  {
    width: 100%;
    height: auto;
    min-height: 120px;
    background-color: #E8EEEE;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;

    #app-bar-content {
        width: 100%;
        max-width: 1185px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 7%;
        margin-right: 7%;

        .title {
            font-size: 36px !important;
        }

        .ansa-logo {
            width: 242px;
        }
    }
}

@media only screen and (max-width: 500px) {
    #app-bar #app-bar-content .title {
        font-size: 30px !important;
    }

    #app-bar #app-bar-content .ansa-logo {
        margin-top: 15px;
        width: 137px !important;
    }
}
</style>