<template>
    <div class="my-10">
        <v-img
            class="mb-10 mx-auto"
            width="180px"
            height="auto"
            src="/img/404.png" />
        <div class="text-h2 font-weight-bold text-center mb-3">{{ $t('not_found.title') }}</div>
        <div class="d-flex justify-center mt-5">
            <v-btn class="elevation-0 mt-5" height="50px" @click="goToAnsa">
                <v-icon class="mr-3">mdi-arrow-left</v-icon>
                <div class="text-body-2 ansa-grey-text" style="text-transform: none">{{ $t('not_found.go_back_to_ansa') }}</div>
            </v-btn>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'not-found-view',
    methods: {
        goToAnsa() {
            window.location.href = process.env.VUE_APP_ANSA_URL;
        }
    }
};
</script>